html {
    height: 100%;
}

body {
    height: 100%;
}

.App {
    text-align: center;
}

.passResult {
    color: #87bc40;
}

.warnResult {
    color: #faa24d;
}

.failResult {
    color: #dd2222;
}

.errorResult {
    color: #dd2222;
}